<template>
  <v-container>
    <v-row>
      <v-col v-if="loading">
     
        <v-skeleton-loader
          class="mt-4"
          type="article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
        ></v-skeleton-loader>
      </v-col>

      <v-col v-if="!loading && user" cols="12" md="8" offset-md="2" >
        <!-- User Info Card -->
        <v-card class="mb-4 glass">
          <v-card-text>
            <v-row align="center">
              <v-col cols="auto">
                <UserImage />
              </v-col>
              <v-col>
                <div class="headline mb-1">{{ user.displayName }}</div>
              </v-col>

              <v-col cols="12"  v-if="user.expired >= 0">
                  <v-btn block
                    x-large
                    elevation="0"
                    :color="user.expired > 5 ? '' : 'error'"
                    
                    @click="$router.push('/pago')"
                  >
                    Vence
                    <template v-if="user.expired >= 1">
                      en {{ user.expired >= 1 ? user.expired + ' días ' : user.expired + ' día ' }}
                    </template>
                    <template v-else>
                      Hoy
                    </template>
                    <v-btn small  class="ml-4" color="success">
                      <v-icon left>mdi-credit-card-outline</v-icon>
                      Pagar
                    </v-btn>
                  </v-btn>         
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>       

        <v-btn
          color="warning darken-3"
          elevation="0"
          class="my-4"
          block
          rounded
          v-if="user.endOfSubscription && user.expired < 0"
        >
          <v-icon left small>mdi-alert</v-icon>
          Cuenta vencida
          <v-btn small rounded class="ml-2" color="success" @click="$router.push('/pago')">
            <v-icon left>mdi-credit-card-outline</v-icon>
            Pagar
          </v-btn>
        </v-btn>

        <!-- Section: Objetivos / Lesiones -->
        <Preferences :user="user"  class="mb-4"/>

        <!-- Section: Datos -->
        <v-card class="mb-4 glass">
          <v-card-title>
            <v-icon left>mdi-information-outline</v-icon>
            Datos
          </v-card-title>
          <v-card-text>
            <v-list>
              <!-- User Details -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><v-icon>mdi-email</v-icon> Email</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="user.phoneNumber && user.phoneNumber != ''">
                <v-list-item-content>
                  <v-list-item-title><v-icon>mdi-phone</v-icon> Teléfono</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.phoneNumber.replace('+598', '0') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="user.address && user.address != ''">
                <v-list-item-content>
                  <v-list-item-title><v-icon>mdi-map-marker</v-icon> Dirección</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="user.birthDate">
                <v-list-item-content>
                  <v-list-item-title><v-icon>mdi-calendar</v-icon> Fecha de Nacimiento</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.birthDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="user.medicalService">
                <v-list-item-content>
                  <v-list-item-title><v-icon>mdi-hospital-building</v-icon> Servicio Médico</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.medicalService }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Section: Suscripción -->
        <v-card class="mb-4 glass" v-if="user.expired">
          <v-card-title>
            <v-icon left>mdi-cash</v-icon>
            Pagos
          </v-card-title>
          <v-card-text>
            <v-btn block x-large class="my-4" disabled>
              <v-icon left>mdi-history</v-icon>
              Historial
            </v-btn>
            <v-btn block x-large color="success" class="mb-4" @click="$router.push('/pago')">
              <v-icon left>mdi-credit-card</v-icon>
              Pagar
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Section: Apariencia -->
        <v-card class="mb-4 glass">
          <v-card-title>
            <v-icon left v-if="$store.state.isDark">mdi-weather-night</v-icon>
            <v-icon left v-else>mdi-weather-sunny</v-icon>
            Apariencia
          </v-card-title>
          <v-card-text>
            <v-list class="py-0">
              <v-list-item three-line  class="">
                <v-list-item-content >
                  <v-list-item-subtitle class="mb-2">
                    Selecciona el modo de apariencia
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Recomendamos el modo oscuro ya que es más amigable con la vista.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-row no-gutters class="px-6" style="height: 50px;">
              <v-col class="d-flex justify-center">
                <span class="pr-4 pt-4 caption" style="font-size: 14px; font-weight: 400; opacity: 0.9;">
                  <v-icon>mdi-weather-sunny</v-icon>
                  Claro
                </span>
                <v-switch
                  class="pt-4"
                  style="margin-top: 0px !important;"
                  color="primary"
                  @change="setTheme"
                  :input-value="$vuetify.theme.dark"
                ></v-switch>
                <span class="pl-4 pt-4 caption" style="font-size: 14px; font-weight: 400; opacity: 0.9;">
                  <v-icon>mdi-weather-night</v-icon>
                  Oscuro
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Section: Seguridad -->
        <v-card class="mb-4 glass">
          <v-card-title>
            <v-icon left>mdi-lock</v-icon>
            Seguridad
          </v-card-title>
          <v-card-text>
            <v-btn color="primary" x-large block class="my-3" @click="dialogChangePassword = true">
              <v-icon left>mdi-lock</v-icon>
              Cambiar Contraseña
            </v-btn>
            <v-btn x-large block class="my-3" @click="logout">
              <v-icon left>mdi-logout</v-icon>
              Cerrar Sesión
            </v-btn>
            <!-- Change Password Dialog -->
            <v-dialog v-model="dialogChangePassword">
              <v-card>
                <v-card-title>
                  Cambio de Contraseña
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialogChangePassword = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form ref="passchange">
                    <v-text-field
                      v-model="currentPassword"
                      :rules="passwordRules"
                      autocomplete="current-password"
                      :disabled="loading"
                      :error-messages="errors"
                      label="Contraseña actual"
                      name="password"
                      dense
                      filled
                      rounded
                      prepend-inner-icon="mdi-lock"
                      :type="showPassword ? 'password' : 'text'"
                    >
                      <template v-slot:append>
                        <v-icon @click="showPassword = !showPassword">
                          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-divider class="mt-2 mb-4"></v-divider>
                    <v-text-field
                      v-model="password"
                      :rules="passwordRules"
                      autocomplete="new-password"
                      :disabled="loading"
                      :error-messages="errors"
                      label="Nueva Contraseña"
                      name="password"
                      dense
                      filled
                      rounded
                      prepend-inner-icon="mdi-lock"
                      :type="showPassword ? 'password' : 'text'"
                    >
                      <template v-slot:append>
                        <v-icon @click="showPassword = !showPassword">
                          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-model="password2"
                      :rules="[
                        ...passwordRules,
                        (v) => v === password || 'Las contraseñas no coinciden',
                      ]"
                      autocomplete="new-password"
                      :disabled="loading"
                      :error-messages="errors"
                      label="Repite la contraseña"
                      name="password2"
                      dense
                      filled
                      rounded
                      prepend-inner-icon="mdi-lock"
                      :type="showPassword ? 'password' : 'text'"
                    >
                      <template v-slot:append>
                        <v-icon @click="showPassword = !showPassword">
                          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-card-actions class="pa-0 mt-4">
                      <v-spacer></v-spacer>
                      <v-btn rounded large :loading="loadingConfirm" color="success" @click="changePassword">
                        <v-icon left>mdi-check</v-icon>
                        Confirmar Cambio
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
<!-- 
        <LicsenceManagerVue
          class="mt-2"
          :user="user"
          v-if="user && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)"
        ></LicsenceManagerVue> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  runTransaction,
  Timestamp,
} from 'firebase/firestore';
import { getAuth, updatePassword } from 'firebase/auth';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

import LoginManager from '../models/login.js';
import moment from 'moment';

import LicsenceManagerVue from '@/components/LicsenceManager.vue';
import User from '@/models/user.js';

import UserImage from '@/components/profile/UserImage.vue';

import Preferences from '@/components/training/Preferences.vue';

export default {
  name: 'UserProfile',
  components: {
    LicsenceManagerVue,
    UserImage,
    Preferences,
  },
  async mounted() {
    this.$vuetify.goTo(0);
    const db = getFirestore();
    let user = this.$store.state.Auth.token.claims.user_id;
    const docRef = doc(db, `users/${user}`);
    const docSnap = await getDoc(docRef);
    this.user = new User({
      id: docSnap.id,
      ...docSnap.data(),
    });

    if (this.user.disabled) {
      let today = moment().toDate();
      await this.setAlert('user_disabled', today, null);
      this.$notify({
        group: 'feedback',
        title: 'Error',
        type: 'error',
        text: 'Tu usuario ha sido deshabilitado, contacta con el administrador.',
      });
      this.logout();
    }

    if (this.user.endOfSubscription) {
      let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000);
      let today = moment().toDate();
      if (endOfSubscription < today) {
        await this.setAlert('user_subscription_expired', today);
        this.$router.push({ name: 'suscripcion' });
      } else {
        let diff = endOfSubscription.getTime() - today.getTime();
        let days = Math.ceil(diff / (1000 * 3600 * 24));
        if (days <= 5) {
          this.alert = days;
        }
      }
    }
    this.initials = this.getInitials(this.user.displayName);
    this.loading = false;

    let url = new URL(window.location.href);
    let changePassword = url.searchParams.get('changePassword');
    if (changePassword) {
      this.dialogChangePassword = true;
    }
  },
  methods: {
    async setAlert(type, date, description) {
      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id;

      try {
        const timestampDate = Timestamp.fromDate(new Date(date));

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false,
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        const alertRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(alertRef, newAlert);
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    },
    logout() {
      LoginManager.logout();
    },
    getInitials(displayName) {
      let arr = displayName.split(' ');
      if (arr.length === 1) return arr[0].charAt(0).toUpperCase();
      else return (arr[0][0] + arr[1][0]).toUpperCase();
    },
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch('changeTheme', this.$vuetify.theme.dark);
    },
    setColor(color) {
      this.$vuetify.theme.themes.dark.primary = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$store.dispatch('changePrimary', color);
    },
    async setLang(lang) {
      await this.$store.dispatch('changeLang', lang);
    },
    async changePassword() {
      this.$refs.passchange.validate();
      if (this.$refs.passchange.validate()) {
        this.loadingConfirm = true;
        const auth = getAuth();

        const user = auth.currentUser;

        try {
          const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

          let result_re_auth = await reauthenticateWithCredential(user, credential);
          if (!result_re_auth) {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña actual no es correcta',
              type: 'error',
            });
            this.loadingConfirm = false;
            return;
          }

          const newPassword = this.password;
          let resultUpdatePassword = await updatePassword(user, newPassword);

          this.password = '';
          this.password2 = '';
          this.dialogChangePassword = false;
          this.loadingConfirm = false;

          this.$notify({
            group: 'feedback',
            title: 'Contraseña cambiada',
            text: 'Tu contraseña ha sido cambiada con éxito',
            type: 'success',
          });

          this.$router.push('/inicio');
        } catch (error) {
          if (error.code == 'auth/wrong-password') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña actual no es correcta',
              type: 'error',
            });
          }

          if (error.code == 'auth/weak-password') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña debe tener al menos 6 caracteres',
              type: 'error',
            });
          }

          if (error.code == 'auth/requires-recent-login') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'Debes volver a iniciar sesión para cambiar la contraseña',
              type: 'error',
            });
          }

          this.$notify({
            group: 'feedback',
            title: 'Error',
            text: 'Hubo un error al cambiar la contraseña',
            type: 'error',
          });
          this.loadingConfirm = false;
          console.log(error);
        }
      }
    },
  },
  data() {
    return {
      alert: false,
      dialogChangePassword: false,
      initials: '',
      loading: true,
      loadingConfirm: false,
      user: null,
      colorGroups: ['#2196f3', '#414141', '#4c4faf', '#af4d4d', '#9C27B0', '#795548'],
      language: this.$store.state.lang == 'es' ? 0 : 1,
      modalChangePassword: false,
      loadingButton: false,
      langs: [
        { key: 'es', value: 'Español' },
        { key: 'en', value: 'English' },
      ],
      password: '',
      password2: '',
      currentPassword: '',
      errors: [],
      passwordRules: [
        (v) => !!v || 'La contraseña es requerida',
        (v) => v.length >= 6 || 'La contraseña debe tener al menos 6 caracteres',
      ],
      showPassword: true,
      tab: 0,
    };
  },
};
</script>

<style>
#avatar-skeleton > .v-skeleton-loader__avatar {
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
}

#avatar-heading > .v-skeleton-loader__heading {
  display: block;
  margin: auto;
}

/* Additional styling for User Info Card */
.user-info-card .v-card-text {
  padding: 16px;
}

.user-info-card .headline {
  font-weight: bold;
  font-size: 24px;
}

.user-info-card .v-btn {
  margin-top: 8px;
}
</style>
